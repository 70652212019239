import Layout from 'components/layout';
import Magnifier from 'components/magnifier';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

// Styles
import * as styles from './before-and-after.module.scss';

const BeforeAndAfterPage = () => {
  const description = 'Reverso is a Fractional RF device that incorporates several innovative technological solutions, all intended to guarantee optimal clinical results skin resurfacing, tissue remodeling, stretch mark treatment and anti-aging treatment.';

  return (
    <Layout title="Reverso by INDIBA | Deep Fractional Radiofrequency for Skin Aging Treatment" meta={{ description, image: 'https://intelis-public.s3.us-east-2.amazonaws.com/image-device.png' }}>
      <div className={styles.Wrapper}>
        <div className={styles.ContentSection}>
          <div className={styles.Content}>
            <h1>Treatment Results</h1>
            <div className={styles.GridColumnTwo}>
              <Magnifier
                text={(<p>2 months from a single treatment</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-1.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-1.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-1.jpeg" className={styles.MagnifierImageModal} alt="before" width={570} height={369} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-1.jpeg" className={styles.MagnifierImageModal} alt="after" width={569} height={373} />
              )}
              />
              <Magnifier
                text={(
                  <p className={styles.TextLeft}>
                    4 weeks from
                    {' '}
                    <strong>2nd treatment</strong>
                    . Impressive reduction of:
                    {' '}
                    <br />
                    {' '}
                    -
                    {' '}
                    <strong>Forehead lines</strong>
                    {' '}
                    <br />
                    {' '}
                    -
                    {' '}
                    <strong>Frown lines</strong>
                    (between the eyebrows)
                  </p>
              )}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-2.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-2.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-2.jpeg" className={styles.MagnifierImageModal} alt="before" width={631} height={401} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-2.jpeg" className={styles.MagnifierImageModal} alt="after" width={648} height={401} />
              )}
              />
              <Magnifier
                text={(
                  <p className={styles.TextLeft}>
                    4 weeks from 2nd treatment. Already apparent:
                    {' '}
                    <br />
                    - Skin lifting and tightening
                    {' '}
                    <br />
                    - Much shallower nasolabial folds
                    {' '}
                    <br />
                    - Reduced ‘bags’ under the eyes
                  </p>
              )}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-3.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-3.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-3.jpeg" className={styles.MagnifierImageModal} alt="before" width={620} height={462} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-3.jpeg" className={styles.MagnifierImageModal} alt="after" width={676} height={464} />
              )}
              />
              <Magnifier
                text={(<p>4 weeks after a single treatment.</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-4.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-4.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-4.jpeg" className={styles.MagnifierImageModal} alt="before" width={1407} height={1650} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-4.jpeg" className={styles.MagnifierImageModal} alt="after" width={1321} height={1674} />
              )}
              />
              <Magnifier
                text={(<p>4 weeks after a single treatment.</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-5.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-5.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-5.jpeg" className={styles.MagnifierImageModal} alt="before" width={1292} height={1667} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-5.jpeg" className={styles.MagnifierImageModal} alt="after" width={1280} height={1689} />
              )}
              />
              <Magnifier
                text={(
                  <p className={styles.TextLeft}>
                    4 weeks after a single treatment. Already apparent:
                    {' '}
                    <br />
                    {' '}
                    - Skin lifting and tightening
                    {' '}
                    <br />
                    {' '}
                    - Shrinking of enlarged pores
                  </p>
              )}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-6.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-6.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-6.jpeg" className={styles.MagnifierImageModal} alt="before" width={439} height={502} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-6.jpeg" className={styles.MagnifierImageModal} alt="after" width={473} height={503} />
              )}
              />
              <Magnifier
                text={(<p>4 weeks from 2nd treatment.</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-7.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-7.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-7.jpeg" className={styles.MagnifierImageModal} alt="before" width={417} height={563} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-7.jpeg" className={styles.MagnifierImageModal} alt="after" width={417} height={563} />
              )}
              />
              <Magnifier
                text={(
                  <p className={styles.TextLeft}>
                    4 weeks from 2nd treatment. Impressive reduction of:
                    {' '}
                    <br />
                    {' '}
                    - Crows Feet (to the side of the eyes)
                    {' '}
                    <br />
                    {' '}
                    - Forehead lines
                    {' '}
                    <br />
                    {' '}
                    - Marionette lines (to the sides of the chin)
                  </p>
              )}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-8.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-8.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-8.jpeg" className={styles.MagnifierImageModal} alt="before" width={438} height={507} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-8.jpeg" className={styles.MagnifierImageModal} alt="after" width={414} height={509} />
              )}
              />
              <Magnifier
                text={(<p>4 weeks from 2nd treatment.</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-14.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-14.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-14.jpeg" className={styles.MagnifierImageModal} alt="before" width={446} height={533} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-14.jpeg" className={styles.MagnifierImageModal} alt="after" width={413} height={533} />
              )}
              />
              <Magnifier
                text={(<p> 2 months after a single treatment.</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-9.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-9.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-9.jpeg" className={styles.MagnifierImageModal} alt="before" width={446} height={533} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-9.jpeg" className={styles.MagnifierImageModal} alt="after" width={413} height={533} />
              )}
              />
              <Magnifier
                text={(<p> 5 months after a single treatment.</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-10.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-10.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-10.jpeg" className={styles.MagnifierImageModal} alt="before" width={446} height={533} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-10.jpeg" className={styles.MagnifierImageModal} alt="after" width={413} height={533} />
              )}
              />
              <Magnifier
                text={(<p> 5 months after a single treatment.</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-11.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-11.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-11.jpeg" className={styles.MagnifierImageModal} alt="before" width={446} height={533} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-11.jpeg" className={styles.MagnifierImageModal} alt="after" width={413} height={533} />
              )}
              />
              <Magnifier
                text={(<p> 5 months after a single treatment.</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-12.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-12.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-12.jpeg" className={styles.MagnifierImageModal} alt="before" width={446} height={533} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-12.jpeg" className={styles.MagnifierImageModal} alt="after" width={413} height={533} />
              )}
              />
              <Magnifier
                text={(<p>1 week after a single treatment.</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-15.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-15.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
              )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-15.jpeg" className={styles.MagnifierImageModal} alt="before" width={446} height={533} />
              )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-15.jpeg" className={styles.MagnifierImageModal} alt="after" width={413} height={533} />
              )}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BeforeAndAfterPage;
